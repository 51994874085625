<template>
  <div>
    <filter-headline :title="title" @click="reset()" :show-reset="activeFilters.length > 0"/>
    <ul>
      <li v-for="item in slicedItems" v-if="item.count >= threshold">
        <label>
          <input type="checkbox" @click="toggleFilter(item.key)" :checked="isActive(item.key)"/>
          <span class="checkmark"/>
          {{ item.key }} ({{ item.count }})
        </label>
      </li>
    </ul>
    <div v-if="slicedItems.length === 0">Keine Ergebnisse</div>
  </div>
</template>

<script>
import FilterHeadline from '@/components/filter/FilterHeadline';
export default {
  name: 'AggregationFilter',
  components: {FilterHeadline},
  props: {
    title: String,
    items: Array,
    filters: Array,
    threshold: {
      type: Number,
      default: 1
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function (data) {
        this.activeFilters = data;
      }
    }
  },
  computed: {
    slicedItems() {
      return this.items.slice(0, 18);
    }
  },
  data() {
    return {
      activeFilters: this.filters
    }
  },
  methods: {
    reset() {
      this.activeFilters = [];
      this.$emit('change', this.activeFilters);
    },
    isActive(item) {
      return this.activeFilters.indexOf(item) > -1;
    },
    toggleFilter(item) {
      const index = this.activeFilters.indexOf(item);

      if (index > -1) {
        this.activeFilters.splice(index, 1);
      } else {
        this.activeFilters.push(item);
      }

      this.$emit('change', this.activeFilters)
    }
  }
}
</script>
