<template>
  <div class="filter-menu">
    <div class="layout">
      <gmaps class="grid-map"/>
      <aggregation-filter
          class="grid-country"
          title="Land"
          :items="$store.state.aggregations.countries.slice(0,5)"
          :filters="$store.state.countries"
          @change="updateCountries"
      />
      <aggregation-filter
          class="grid-city"
          title="Stadt"
          :items="$store.state.aggregations.cities.slice(0,5)"
          :filters="$store.state.cities"
          @change="updateCities"
      />
      <aggregation-filter
          class="grid-product"
          title="Produkt"
          :items="$store.state.aggregations.products.slice(0,10)"
          :filters="$store.state.products"
          @change="updateProducts"
      />
    </div>
  </div>
</template>

<script>
import AggregationFilter from '@/components/filter/AggregationFilter';
import Gmaps from '@/components/gmaps/gmaps';

export default {
  name: 'FilterMenu',
  components: {Gmaps, AggregationFilter},
  methods: {
    updateCountries(countries) {
      this.$store.commit('SET_COUNTRY_FILTER', countries);
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('refreshSearch', {
        'country': false,
        'city': true,
        'product': true
      });
    },
    updateCities(cities) {
      this.$store.commit('SET_CITY_FILTER', cities);
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('refreshSearch', {
        'country': false,
        'city': false,
        'product': true
      });
    },
    updateProducts(products) {
      this.$store.commit('SET_PRODUCT_FILTER', products);
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('refreshSearch', {
        'country': false,
        'city': false,
        'product': false
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
