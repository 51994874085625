<template>
  <div class="standard-provider">
    <a :href="url">
      <div class="standard-provider__layout">
        <company-icon />
        <div style="border-bottom: 5px solid #eee; padding-bottom:20px">
          <h4 class="provider-box__title"><strong>{{ provider.name }}</strong></h4>
          <p class="provider-box__p">{{ provider.zipcode }} - {{ provider.city }}</p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import {createProviderUrl} from '@/utils/utils';
import CompanyIcon from '@/components/svgs/CompanyIcon';

export default {
  name: 'StandardProvider',
  components: {CompanyIcon},
  props: {
    provider: Object
  },
  computed: {
    url() {
      return createProviderUrl(this.provider);
    }
  }
}
</script>
