<template>
  <div class="pcs__autocomplete__item"
       @click="$emit('click', term)"
       :class="highlighted ? 'pcs__autocomplete__item--highlighted' : ''"
  >
    <span class="item" v-html="name"/> <span class="item-type">(Produktkategorie)</span>
  </div>
</template>

<script>
import {highlightString} from '@/utils/utils';

export default {
  name: 'AutoCompleteProductCategory',
  props: {
    data: Object,
    term: String,
    highlighted: Boolean
  },
  computed: {
    name() {
      return highlightString(this.term, this.data.mainCategory + ' - ' + this.data.topCategory);
    },
  },
}
</script>
