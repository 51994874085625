<template>
  <div id="app" class="pcs" v-if="mounted">
    <div :class="hasTerm ? 'pcs__search-input-wrapper' : ''">
      <auto-complete-search v-if="mounted" :term="$store.state.term"
                            @clearsearch="showFilters = false; hasTerm = false"/>

      <button class="filter-button"
              v-if="hasTerm"
              @click="showFilters = !showFilters"
              :class="showFilters ? 'filter-button--active' : ''"
      >
        <filter-icon/>
        <span class="button-value">
          <span>{{ $store.state.pager.totalItems }} Anbieter</span>
        </span>
      </button>
    </div>

    <transition name="fade">
    <filter-menu v-show="showFilters"/>
    </transition>

    <div cLass="pt-24" id="ak-search-results" v-if="hasTerm">
      <search-results/>
      <paginator/>
    </div>

  </div>
</template>

<script>
import SearchResults from '@/components/searchresult/SearchResults';
import Paginator from '@/components/Paginator';

import AutoCompleteSearch from '@/components/autocomplete/AutoCompleteSearch';
import FilterMenu from '@/components/filter/FilterMenu';
import FilterIcon from '@/components/svgs/FilterIcon';

export default {
  components: {
    FilterIcon,
    FilterMenu,
    AutoCompleteSearch,
    Paginator,
    SearchResults
  },
  data() {
    return {
      mounted: false,
      showFilters: false,
      hasTerm: false,
    }
  },
  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const term = urlParams.get('q');

    const dataEl = document.getElementById('aksearch-data');
    const apiUrl = dataEl.dataset.apiurl;

    this.$store.commit('SET_API_URL', apiUrl);

    const type = urlParams.get('type');
    if (type) {
      this.$store.commit('SET_SEARCH_TYPE', type);
    }

    if (term) {
      this.hasTerm = true;
      this.$store.commit('SET_TERM', term);
      await this.$store.dispatch('refreshSearch');
      this.showFilters = this.$store.state.pager.totalItems > 20;
    }

    this.mounted = true;
  }
}
</script>

