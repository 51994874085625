var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.consent)?_c('div',{staticClass:"gmaps"},[_c('filter-headline',{attrs:{"title":"Karte","show-reset":_vm.newLocation.lat !== null},on:{"click":function($event){return _vm.clearMarker()}}}),_c('div',{staticClass:"gmaps__wrapper"},[_c('div',{staticClass:"gmaps__overlay"},[_c('gmap-autocomplete',{staticClass:"gmaps__autocomplete",attrs:{"value":_vm.place,"placeholder":"Ort eingeben oder auf Karte wählen","select-first-on-enter":true,"options":{ types: ['(cities)'], componentRestrictions: {country: _vm.matchCountries} }},on:{"place_changed":_vm.setPlace}})],1),_c('GmapMap',{staticClass:"map",attrs:{"center":_vm.mapCenter,"zoom":5,"map-type-id":"roadmap","options":{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false
          }},on:{"click":_vm.setMarker}},[(_vm.$store.state.location.lat !== null)?_c('GmapCircle',{attrs:{"center":{lat:_vm.$store.state.location.lat, lng:_vm.$store.state.location.lng},"radius":_vm.newDistance * 1000,"visible":true,"clickable":true,"options":{
              strokeWeight: 0,
              fillColor: '#00ffff',
              fillOpacity: 0.5,
              enableEventPropagation: true
            }},on:{"click":_vm.setMarker}}):_vm._e()],1),_c('div',{staticClass:"gmaps__slider"},[_c('vue-slider',{staticStyle:{"height":"10px"},attrs:{"min":5,"max":250},on:{"drag-end":_vm.change},model:{value:(_vm.newDistance),callback:function ($$v) {_vm.newDistance=$$v},expression:"newDistance"}}),_vm._v(" Entfernung: "+_vm._s(_vm.newDistance)+"km ")],1)],1)],1):_c('div',{staticClass:"gmaps"},[_vm._v("Google Maps wurde wegen fehlendem Consent nicht geladen.")])}
var staticRenderFns = []

export { render, staticRenderFns }