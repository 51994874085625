import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiUrl: '',
        term: '',
        searchType: 'a',
        location: {
            lat: null,
            lng: null,
            distance: 25
        },
        cities: [],
        countries: [],
        products: [],
        results: [],
        aggregations: {
            cities: [],
            countries: [],
            products: []
        },
        pager: {
            current: 1,
            totalItems: 0,
            maxPages: 0
        },

    },
    mutations: {
        SET_API_URL: (state, url) => {
            state.apiUrl = url;
        },
        SET_PAGE: (state, page) => {
            state.pager.current = page;
        },
        SET_TERM: (state, term) => {
            state.term = term;
        },
        SET_SEARCH_TYPE: (state, searchType) => {
            state.searchType = searchType;
        },
        SET_LOCATION: (state, location) => {
            state.location = location;
        },
        SET_RESULTS: (state, results) => {
            state.results = results;
        },
        SET_COUNTRY_AGG: (state, results) => {
            state.aggregations.countries = results;
        },
        SET_CITY_AGG: (state, results) => {
            state.aggregations.cities = results;
        },
        SET_PRODUCT_AGG: (state, results) => {
            state.aggregations.products = results;
        },
        SET_COUNTRY_FILTER: (state, countries) => {
            state.countries = countries;
        },
        SET_CITY_FILTER: (state, cities) => {
            state.cities = cities;
        },
        SET_PRODUCT_FILTER: (state, products) => {
            state.products = products;
        },
    },
    actions: {
        async refreshSearch({commit, state}, options = {'country': true, 'city': true, 'product': true}) {
            if (state.term.length < 2) {
                return;
            }

            if (options.country) {
                commit('SET_COUNTRY_FILTER', []);
            }

            if (options.city) {
                commit('SET_CITY_FILTER', []);
            }

            if (options.product) {
                commit('SET_PRODUCT_FILTER', []);
            }

            let url = state.apiUrl + '/wcossearch/search?q=' + state.term;

            if (state.searchType) {
                url += '&type=' + state.searchType;
            }

            if (state.location.lat !== null) {
                url += '&lat=' + state.location.lat + '&lng=' + state.location.lng + '&distance=' + state.location.distance;
            }

            if (state.countries.length > 0) {
                state.countries.map(filter => {
                    url += '&countries[]=' + filter;
                });
            }

            if (state.cities.length > 0) {
                state.cities.map(filter => {
                    url += '&cities[]=' + filter;
                });
            }

            if (state.products.length > 0) {
                state.products.map(filter => {
                    url += '&products[]=' + filter;
                });
            }

            url += '&page=' + state.pager.current;
            url = encodeURI(url);

            const result = await axios.get(url);

            commit('SET_RESULTS', result.data.items);

            if (options.country) {
                commit('SET_COUNTRY_AGG', result.data.aggs.country);
            }

            if (options.city) {
                commit('SET_CITY_AGG', result.data.aggs.city);
            }

            if (options.product) {
                commit('SET_PRODUCT_AGG', result.data.aggs.products);
            }

            state.pager = result.data.pager;
        }
    },
    modules: {}
})
