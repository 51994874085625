<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22">
    <path d="M22.542 19.991l-6-6c2.559261575693-3.860320002313 1.779682834265-9.031477244134-1.803896115593-11.96573221656S5.931202108938-.653040137456 2.65166379196 2.617513143037s-3.549814482099 8.493154263556-.625402026108 12.084769794249S10.119673244857 19.087661237205 13.987 16.539l6 6zM2.555 9.019c0-3.568864053407 2.893135946593-6.462 6.462-6.462s6.462 2.893135946593 6.462 6.462-2.893135946593 6.462-6.462 6.462c-1.713910271854.000265290659-3.357699400554-.680465419263-4.569616990646-1.892383009354S2.554734709341 10.732910271854 2.555 9.019z" fill="#878787"/>
  </svg>
</template>

<script>
export default {
  name: 'LensIcon'
}
</script>

