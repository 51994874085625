<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="15" height="15"
       viewBox="0 0 64 64" xml:space="preserve">

		<path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z"
          stroke-width="2px"
          stroke="#fff"
          fill="#fff"
    />
</svg>
</template>

<script>
export default {
  name: 'FilterIcon'
}
</script>

