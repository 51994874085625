<template>
  <a class="premium-provider" :href="url">
    <img v-if=" provider.logo !== ''" :alt="provider.name" :src="'https://cdn4.weka-fachmedien.de/thumbs/anbieter/' + provider.logo + '.400x254.logo.png'"/>
    <div v-else class="logo-fallback">{{ provider.name }}</div>
    <div class="description">
      {{ provider.name }}
      <p>{{ provider.zipcode}} - {{ provider.city }}</p>
    </div>
  </a>
</template>

<script>
import {createProviderUrl} from '@/utils/utils';

export default {
  name: 'PremiumProvider',
  props: {
    provider: Object
  },
  computed: {
    url() {
      return createProviderUrl(this.provider);
    }
  }
}
</script>
