/**
 * @param {Object} provider
 * @returns {string}
 */
export function createProviderUrl(provider) {
    return '/anbieterkompass/' + provider.slug + '.' + provider.id + '/index.html';
}

export function highlightString(term, string) {
    const terms = term
        .trim()
        .replace('-', ' ')
        .split(' ');

    terms.map(termEntry => {
        if (termEntry.length > 0) {
            const t = termEntry.trim().replace(/(\W)/g, '\\$1');

            let regex = new RegExp(t, 'i')
            string = string.replace(regex, '|$&||');
        }
    })

    string = string.replaceAll('||', '</span>');
    string = string.replaceAll('|', '<span class="item--highlight">');

    return string;
}

export function prepareTerm(term) {
    let preparedTerm = term.replace('&', encodeURIComponent('&'));
    preparedTerm = preparedTerm.replace('-', encodeURIComponent('-'));

    return preparedTerm;
}
