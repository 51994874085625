<template>
  <nav class="pagination" v-if="pager.maxPages > 1">
    <a @click="prevPage()" title="Eine Seite vor" v-if="pager.current > 1">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20">
        <path fill="none" stroke="#000" stroke-width="1.03" d="M13 16l-6-6 6-6"/>
      </svg>
    </a>

    <a @click="setPage(1)" class="hide-for-small" v-if="pages.first > 1">1</a>
    <span v-if="pages.first-1 > 1">…</span>

    <a class="hide-for-small" v-for="page in pages.items" v-if="page.page !== pager.current"
       @click="setPage(page.page)">{{ page.page }}</a>
    <span v-else>{{ pager.current }}</span>

    <span v-if="pages.last +1 < pager.maxPages">…</span>
    <a v-if="pages.last < pager.maxPages" @click="setPage(pager.maxPages)">{{ pager.maxPages }}</a>

    <a
        @click="nextPage()"
        v-if="pager.current < pager.maxPages"
        title="Eine Seite vor">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20">
        <path fill="none" stroke="#000" stroke-width="1.03" d="M7 4l6 6-6 6"/>
      </svg>
    </a>
  </nav>

</template>

<script>
export default {
  name: 'Paginator',
  data() {
    return {
      options:  {
        'country': false,
        'city': false,
        'product': false
      }
    }
  },
  computed: {
    pager() {
      return this.$store.state.pager;
    },
    pages() {
      const gap = 3;
      let start = 1;
      if (this.pager.current > gap) {
        start = this.pager.current - gap;
      }

      let end = start + 2 * gap;
      if (end > this.pager.maxPages) {
        end = this.pager.maxPages;
      }

      const pages = {
        first: start,
        last: end,
        items: []
      };
      for (let i = start; i <= end; i++) {
        pages.items.push({page: i});
      }

      return pages;
    }
  },
  methods: {
    scrollToResults() {
      const results = document.getElementById('ak-search-results');
      const top = results.offsetTop - 124;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    },

    setPage(page) {
      this.$store.commit('SET_PAGE', page);
      this.$store.dispatch('refreshSearch', this.options);
      this.scrollToResults();

    },
    prevPage() {
      if (this.pager.current > 1) {
        this.$store.commit('SET_PAGE', this.pager.current - 1);
        this.$store.dispatch('refreshSearch', this.options);
        this.scrollToResults();
      }

    },
    nextPage() {
      if (this.pager.current < this.pager.maxPages) {
        this.$store.commit('SET_PAGE', this.pager.current + 1);
        this.$store.dispatch('refreshSearch', this.options);
        this.scrollToResults();
      }
    },
  }
}
</script>
