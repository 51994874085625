<template>
  <div class="pcs__search-results" v-if="$store.state.results.length > 0">
    <div v-if="premiumProviders.length > 0">
      <h2>Premiumanbieter</h2>
      <div class="premium-provider-results">
        <premium-provider :provider="provider" v-for="provider in premiumProviders"/>
      </div>
    </div>
    <div v-if="standardProviders.length > 0" class="pt-24">
      <h2 v-if="premiumProviders.length > 0">Andere Anbieter</h2>
      <standard-provider :provider="provider" v-for="provider in standardProviders"/>
    </div>
  </div>
  <div v-else>
    <h2>Keine Ergebnisse gefunden</h2>
  </div>
</template>

<script>
import PremiumProvider from '@/components/searchresult/PremiumProvider';
import StandardProvider from '@/components/searchresult/StandardProvider';

export default {
  name: 'SearchResults',
  components: {StandardProvider, PremiumProvider},

  computed: {
    premiumProviders() {
      return this.$store.state.results.filter(item => item.premium);
    },
    standardProviders() {
      return this.$store.state.results.filter(item => !item.premium);
    }
  }
}
</script>

