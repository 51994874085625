<template>
  <div class="search-wrapper">
    <label>
      <lens-icon style="position: absolute; top: 10px; left: 12px;"/>
       <input type="text" v-model="searchTerm"
             placeholder="Firmenname oder Produkt"
             class="pcs__search_input"
             :class="searchTerm.length > 1 && dirty && autoCompleteData.length > 0 ? 'pcs__search_input--autocomplete' : ''"
             @keyup="onKeyUp"
      />
      <div class="search-wrapper-inner" @click="clearSearch()" v-if="searchTerm.length > 1">
        <div class="clear-search"></div>
      </div>
    </label>
    <auto-complete
        v-if="searchTerm.length > 1 && dirty && autoCompleteData.length > 0"
        ref="autocomplete"
        :term="searchTerm"
        :dirty="dirty"
        :tab-index="tabIndex"
        :auto-complete-data="autoCompleteData"
        @reloadSearch="reloadSearch"
    />
  </div>
</template>

<script>
import AutoComplete from '@/components/autocomplete/AutoComplete';
import LensIcon from '@/components/svgs/LensIcon';
import {prepareTerm} from '@/utils/utils'

export default {
  name: 'AutoCompleteSearch',
  components: {LensIcon, AutoComplete},
  props: {
    term: String,
  },
  data() {
    return {
      searchTerm: this.term,
      lastSearchTerm: '',
      dirty: false,
      autoCompleteData: [],
      tabIndex: -1,
    }
  },
  methods: {
    clearSearch() {
      this.searchTerm = '';
      this.$emit("clearsearch");
    },
    onKeyUp(event) {
      this.dirty = true;
      if (event.key === 'Enter') {
        if (this.tabIndex > -1) {
          this.$nextTick(() => {
            const item = this.$refs.autocomplete.$children[this.tabIndex];
            item.$emit('click');
          });
        } else {
          this.reloadSearch(this.searchTerm, 'a');
        }
      } else if (event.key === 'ArrowDown') {
        if (this.autoCompleteData.length > 0) {
          if (this.tabIndex < this.autoCompleteData.length - 1) {
            this.tabIndex++;
          } else {
            this.tabIndex = 0;
          }
        }
      } else if (event.key === 'ArrowUp') {
        if (this.autoCompleteData.length > 0) {
          if (this.tabIndex > 0) {
            this.tabIndex--;
          } else {
            this.tabIndex = this.autoCompleteData.length - 1;
          }
        }
      } else {
        this.tabIndex = -1;
        const term = this.searchTerm;

        window.setTimeout(() => {
          if (term === this.searchTerm && term !== this.lastSearchTerm) {
            this.autoComplete();
          }
        }, 200);

      }
    },
    reloadSearch(entry, type) {
      window.location.href = '/anbieterkompass/suche?q=' + entry + '&type=' + type;
    },
    autoComplete() {
      if (this.searchTerm.length < 2) {
        return;
      }

      this.lastSearchTerm = this.searchTerm;

      this.axios.get(this.$store.state.apiUrl + '/wcossearch/autocomplete?q=' + prepareTerm(this.searchTerm)).then(result => {
        this.autoCompleteData = result.data.slice(0, 15);

      });
    },
  },

}
</script>

