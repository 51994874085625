<template>
  <div v-if="term.length > 1 && dirty" class="pcs__autocomplete">
    <div v-for="(entry, index) in autoCompleteData" v-bind:key="entry.id">
      <auto-complete-product
          v-if="entry.type === 'product'"
          @click="reloadSearch(entry.data.name, 'p')"
          :data="entry.data"
          :term="term"
          :highlighted="index === tabIndex"
      />

      <auto-complete-product-category
          v-if="entry.type === 'category'"
          @click="reloadSearch(entry.data.mainCategory + ' - ' + entry.data.topCategory, 'c')"
          :data="entry.data"
          :term="term"
          :highlighted="index === tabIndex"
      />

      <auto-complete-provider
          v-if="entry.type === 'provider'"
          @click="gotoProvider(entry.data)"
          :data="entry.data"
          :term="term"
          :highlighted="index === tabIndex"
      />
    </div>
  </div>
</template>

<script>
import AutoCompleteProduct from '@/components/autocomplete/AutoCompleteProduct';
import AutoCompleteProductCategory from '@/components/autocomplete/AutoCompleteProductCategory';
import AutoCompleteProvider from '@/components/autocomplete/AutoCompleteProvider';
import {createProviderUrl} from '@/utils/utils';

export default {
  name: 'AutoComplete',
  components: {AutoCompleteProvider, AutoCompleteProductCategory, AutoCompleteProduct},
  props: {
    term: String,
    tabIndex: Number,
    autoCompleteData: Array,
    dirty: Boolean
  },
  methods: {
    reloadSearch(entry, type) {
      console.log(entry);

      this.$emit('reloadSearch', entry, type)
    },
    gotoProvider(entry) {
      const url = createProviderUrl(entry);
      window.location.href = url;
    }
  }
}
</script>

