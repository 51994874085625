<template>
  <div class="pcs__autocomplete__item pcs__autocomplete__item--provider"
       :class="highlighted ? 'pcs__autocomplete__item--highlighted' : ''"
       @click="$emit('click', data)"
  >
    <div>
      <span v-if="data.premium" class="logo">
        <img
            :src="'https://cdn4.weka-fachmedien.de/thumbs/anbieter/' + data.logo + '.60x60.logo.png'"/>
      </span>
    </div>
    <div>
      <span class="item" v-html="name"/> <span class="item-type">({{ premium }})</span>
    </div>
  </div>
</template>

<script>
import {highlightString} from '@/utils/utils';

export default {
  name: 'AutoCompleteProvider',
  props: {
    data: Object,
    term: String,
    highlighted: Boolean
  },
  computed: {
    premium() {
      return (this.data.premium) ? 'Premium Anbieter' : 'Anbieter';
    },
    name() {
      return highlightString(this.term, this.data.name);
    }
  }
}
</script>

