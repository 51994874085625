<template>
<div  class="filter-headline">
  <h4>
    <span>{{ title }}</span>
    <span class="x" @click="dispatchClick" v-if="showReset"> </span>
  </h4>
</div>
</template>

<script>
export default {
  name: 'FilterHeadline',
  props: {
    title: String,
    showReset: Boolean
  },
  methods: {
    dispatchClick() {
      this.$emit('click');
    }
  }
}
</script>
