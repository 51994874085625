<template>
  <div class="gmaps" v-if="consent">
    <filter-headline title="Karte" @click="clearMarker()" :show-reset="newLocation.lat !== null"/>
    <div class="gmaps__wrapper">
      <div class="gmaps__overlay">
        <gmap-autocomplete
            class="gmaps__autocomplete"
            :value="place"
            placeholder="Ort eingeben oder auf Karte wählen"
            :select-first-on-enter="true"
            :options="{ types: ['(cities)'], componentRestrictions: {country: matchCountries} }"
            @place_changed="setPlace"
        />
      </div>
      <GmapMap
          :center="mapCenter"
          @click="setMarker"
          :zoom="5"
          map-type-id="roadmap"
          :options="{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false
            }"
          class="map"
      >
        <GmapCircle
            v-if="$store.state.location.lat !== null"
            @click="setMarker"
            :center="{lat:$store.state.location.lat, lng:$store.state.location.lng}"
            :radius="newDistance * 1000"
            :visible="true"
            :clickable="true"
            :options="{
                strokeWeight: 0,
                fillColor: '#00ffff',
                fillOpacity: 0.5,
                enableEventPropagation: true
              }"
        ></GmapCircle>
      </GmapMap>

      <div class="gmaps__slider">
        <vue-slider
            v-model="newDistance"
            :min="5"
            :max="250"
            @drag-end="change"
            style="height: 10px"
        >
        </vue-slider>
        Entfernung: {{ newDistance }}km
      </div>

    </div>
  </div>
  <div class="gmaps" v-else>Google Maps wurde wegen fehlendem Consent nicht geladen.</div>
</template>

<script>
import FilterHeadline from '@/components/filter/FilterHeadline';
export default {
  name: 'gmaps',
  components: {FilterHeadline},
  data() {
    return {
      consent: false,
      matchCountries: [],
      place: '',
      mapCenter: {
        lat: 51.1083247,
        lng: 9.7344953
      },
      defaultMapCenter: {
        lat: 51.1083247,
        lng: 9.7344953
      },
      newDistance: 100,
      newLocation: {
        lat: null,
        lng: null
      }
    }
  },
  methods: {
    change() {
      if (this.newLocation.lat) {
        this.$store.commit('SET_LOCATION', {
          'lat': this.newLocation.lat,
          'lng': this.newLocation.lng,
          'distance': this.newDistance,
        });

        this.$store.commit('SET_PAGE', 1);
        this.$store.dispatch('refreshSearch');
      }
    },
    clearMarker() {
      this.place = null;
      this.newLocation.lat = null;
      this.newLocation.lng = null;
      this.mapCenter = this.defaultMapCenter;
      this.$store.commit('SET_LOCATION', {
        'lat': null,
        'lng': null,
        'distance': 100,
      });

      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('refreshSearch');
    },
    setPlace(place) {
      if (!place) return

      this.place = place.formatted_address;

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      this.newLocation.lat = lat;
      this.newLocation.lng = lng;

      this.mapCenter = this.newLocation;

      this.change();
    },
    setMarker(marker) {
      this.newLocation.lat = marker.latLng.lat();
      this.newLocation.lng = marker.latLng.lng();

      this.change();
    },
  },
  mounted() {
    const x = __cmp('getCMPData');
    if("vendorConsents" in x)
    {
      if("s1104" in x.vendorConsents && x.vendorConsents["s1104"])
      {
        this.consent = true;
      }
    }
  }
}
</script>

